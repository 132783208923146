<template>
    <b-modal
        id="update-account-modal"
        v-model="state.showing"
        no-fade
        title="Update Account"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        modal-class="full-screen-modal"
    >
        <div class="modal-container">
            <div class="col col-10 col-lg-6 mx-auto">
                <form @submit="sendCode">
                    <h3 class="text-center my-2 my-lg-5">
                        We need your mobile number to enable 2 factor
                        authentication and enhance the security of your user
                        account.
                    </h3>
                    <div class="col col-6 mx-auto card py-3">
                        <div
                            v-if="state.message"
                            :class="{
                                'pt-3 text-center': true,
                                'text-danger': state.error,
                                'text-success': !state.error,
                            }"
                        >
                            {{ state.message }}
                        </div>
                        <!-- <div class="row ml-0 mr-2"> -->
                        <div class="col col-12 mb-3 pl-0">
                            Please enter your mobile number below.
                        </div>
                        <div class="row ml-0">
                            <div class="form-group col col-9 p-0 pr-2 mb-0">
                                <b-form-input
                                    id="mobile_number"
                                    v-model.trim="$v.form.mobile_number.$model"
                                    type="text"
                                    autofocus
                                    :formatter="formatPhone"
                                    name="mobile_number"
                                    :disabled="
                                        state.submitting ||
                                        state.phone_number_lookup.busy
                                    "
                                    placeholder="enter mobile number"
                                    autocomplete="off"
                                    :class="{
                                        'has-error':
                                            $v.form.mobile_number.$error,
                                    }"
                                    @input="checkPhoneNumber"
                                />
                            </div>
                            <div class="col col-3 text-left pl-0">
                                <loading-icon
                                    v-if="state.phone_number_lookup.busy"
                                    title="looking up
                                phone number..."
                                    class-name="d-inline float-left ml-2 mt-2"
                                />
                                <span
                                    v-if="
                                        !state.phone_number_lookup.message &&
                                        state.phone_number_lookup.valid === true
                                    "
                                    class="text-success valid-phone-number"
                                    title="mobile number is valid"
                                >
                                    <i class="fa fa-check mt-2 ml-2"></i>
                                </span>
                            </div>
                        </div>
                        <div
                            v-if="
                                ($v.form.mobile_number.$dirty &&
                                    $v.form.mobile_number.$error) ||
                                state.phone_number_lookup.message
                            "
                            class="input-error col-12 pl-0 mt-2"
                        >
                            {{ getMobileNumberErrorMessage }}
                        </div>
                        <div class="col col-12 p-0 mt-2 text-left">
                            <button
                                type="submit"
                                class="btn btn-success btn-auto-w px-2"
                                :disabled="
                                    state.submitting ||
                                    !state.phone_number_lookup.valid
                                "
                            >
                                Send Code
                            </button>
                            <a
                                class="btn btn-link p-0 pl-3"
                                href="/"
                                @click="doLogout"
                            >
                                Logout
                            </a>
                        </div>
                        <!-- </div> -->
                        <div class="col col-12 px-0 mt-2">
                            <div class="alert alert-info">
                                <strong>Note:</strong> We will send a
                                confirmation code to the phone number supplied
                                above.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </b-modal>
</template>
<script>
const { Vue } = window;
const { required, minLength } = require('vuelidate/lib/validators');

import { MSG_GENERIC_ERROR } from '../config/constants';
import {
    formatPhoneNumber,
    lookupPhoneNumber,
    validatePhoneNumber,
} from '../utils';
import xhrError from '../utils/xhr';

import ConfirmOTP from './ConfirmOTP.vue';

Vue.dialog.registerComponent('confirm-otp', ConfirmOTP);

export default {
    name: 'UpdateAccount',
    props: {
        data: {
            type: Object,
            default: null,
        },
        'phone-number-lookup-route': {
            type: String,
            required: true,
        },
        'update-route': {
            type: String,
            required: true,
        },
        'account-type': {
            type: String,
            default: null,
        },
        'account-id': {
            type: String,
            default: null,
        },
        // 'otp-config': {
        //     type: Object,
        //     required: true,
        // },
    },
    data() {
        return {
            form: {
                mobile_number: '',
            },
            state: {
                showing: true,
                submitting: false,
                sent: false,
                error: false,
                message: null,
                phone_number_lookup: {
                    busy: false,
                    valid: null,
                    message: null,
                },
            },
        };
    },
    computed: {
        getMobileNumberErrorMessage: function () {
            return this.getErrorMessage('mobile_number');
        },
    },
    validations() {
        return {
            form: {
                mobile_number: {
                    required,
                    minLength: minLength(10),
                    isValidPhoneNumber: validatePhoneNumber,
                },
            },
        };
    },
    methods: {
        doLogout(evt) {
            evt.preventDefault();

            document.getElementById('logout-form').submit();
        },
        getErrorMessage() {
            const { state } = this;

            if (!this.$v.form.mobile_number.isValidPhoneNumber) {
                return 'Please enter a valid phone number!';
            }

            if (state.phone_number_lookup.message) {
                return state.phone_number_lookup.message;
            }

            return 'Required!';
        },
        async sendCode(e) {
            const { state } = this;

            e.preventDefault();

            this.$v.$touch();

            state.message = null;
            state.error = false;

            if (this.$v.$invalid) return false;

            state.submitting = true;

            const data = {
                ...this.form,
                action: 'update',
                entity_type: this.accountType,
                entity_id: this.accountId,
            };

            const loader = this.$loading.show();

            await window.axios
                .post('/api/otp/request', data)
                .then((res) => {
                    if (res && res.data) {
                        // this.confirmCode(res.data.id);
                        this.state = {
                            ...this.state,
                            sent: true,
                            error: false,
                            submitting: false,
                            // message:
                            //     'Verification code was sent successfully. Please verify below.',
                        };

                        this.$dialog
                            .alert('Confirm Code', {
                                view: 'confirm-otp',
                                data,
                                confirmAction: this.confirmCode,
                                cancelAction: this.cancelConfirm,
                                customClass: 'confirm-otp-dialog',
                            })
                            .catch(() => {});

                        // hide update modal
                        if (document.getElementById('update-account-modal')) {
                            document.getElementById(
                                'update-account-modal',
                            ).style.display = 'none';
                        }
                    }
                })
                .catch((err) => {
                    state.error = true;
                    // state.message = true;

                    const errBag = xhrError(err);
                    const errMsg = errBag ? errBag.message : MSG_GENERIC_ERROR;

                    state.message = errMsg;
                })
                .finally(() => {
                    this.state.submitting = false;

                    loader.hide();
                });
        },
        formatPhone(str) {
            return formatPhoneNumber(str);
        },
        async checkPhoneNumber(phoneNumber) {
            await lookupPhoneNumber(this, 'phone_number_lookup', phoneNumber);
        },
        async confirmCode(otp_id) {
            const loader = this.$loading.show();

            // otp confirmed, update account phone number.
            await window.axios
                .put(this.updateRoute, {
                    entity_type: this.accountType,
                    entity_id: this.accountId,
                    otp_id,
                    fields: {
                        mobile_number: this.form.mobile_number,
                    },
                })
                .then((res) => {
                    if (res && res.data) {
                        // window.toastr.success(
                        //     'Your account was successfully updated.',
                        // );

                        window.location.reload();
                    }
                })
                .catch((err) => {
                    const errBag = xhrError(err);
                    const errMsg = errBag ? errBag.message : MSG_GENERIC_ERROR;

                    window.toastr.error(errMsg);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        cancelConfirm() {
            // show update modal
            if (document.getElementById('update-account-modal')) {
                document.getElementById('update-account-modal').style.display =
                    'block';
            }
        },
    },
};
</script>
<style scoped>
.dg-container {
    z-index: 99999;
}
</style>
