var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "update-account-modal",
        "no-fade": "",
        title: "Update Account",
        "hide-footer": "",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "full-screen-modal",
      },
      model: {
        value: _vm.state.showing,
        callback: function ($$v) {
          _vm.$set(_vm.state, "showing", $$v)
        },
        expression: "state.showing",
      },
    },
    [
      _c("div", { staticClass: "modal-container" }, [
        _c("div", { staticClass: "col col-10 col-lg-6 mx-auto" }, [
          _c("form", { on: { submit: _vm.sendCode } }, [
            _c("h3", { staticClass: "text-center my-2 my-lg-5" }, [
              _vm._v(
                "\n                    We need your mobile number to enable 2 factor\n                    authentication and enhance the security of your user\n                    account.\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col col-6 mx-auto card py-3" }, [
              _vm.state.message
                ? _c(
                    "div",
                    {
                      class: {
                        "pt-3 text-center": true,
                        "text-danger": _vm.state.error,
                        "text-success": !_vm.state.error,
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.state.message) +
                          "\n                    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 mb-3 pl-0" }, [
                _vm._v(
                  "\n                        Please enter your mobile number below.\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row ml-0" }, [
                _c(
                  "div",
                  { staticClass: "form-group col col-9 p-0 pr-2 mb-0" },
                  [
                    _c("b-form-input", {
                      class: {
                        "has-error": _vm.$v.form.mobile_number.$error,
                      },
                      attrs: {
                        id: "mobile_number",
                        type: "text",
                        autofocus: "",
                        formatter: _vm.formatPhone,
                        name: "mobile_number",
                        disabled:
                          _vm.state.submitting ||
                          _vm.state.phone_number_lookup.busy,
                        placeholder: "enter mobile number",
                        autocomplete: "off",
                      },
                      on: { input: _vm.checkPhoneNumber },
                      model: {
                        value: _vm.$v.form.mobile_number.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.form.mobile_number,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.mobile_number.$model",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col col-3 text-left pl-0" },
                  [
                    _vm.state.phone_number_lookup.busy
                      ? _c("loading-icon", {
                          attrs: {
                            title:
                              "looking up\n                            phone number...",
                            "class-name": "d-inline float-left ml-2 mt-2",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.state.phone_number_lookup.message &&
                    _vm.state.phone_number_lookup.valid === true
                      ? _c(
                          "span",
                          {
                            staticClass: "text-success valid-phone-number",
                            attrs: { title: "mobile number is valid" },
                          },
                          [_c("i", { staticClass: "fa fa-check mt-2 ml-2" })]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              (_vm.$v.form.mobile_number.$dirty &&
                _vm.$v.form.mobile_number.$error) ||
              _vm.state.phone_number_lookup.message
                ? _c("div", { staticClass: "input-error col-12 pl-0 mt-2" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.getMobileNumberErrorMessage) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 p-0 mt-2 text-left" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-auto-w px-2",
                    attrs: {
                      type: "submit",
                      disabled:
                        _vm.state.submitting ||
                        !_vm.state.phone_number_lookup.valid,
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Send Code\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-link p-0 pl-3",
                    attrs: { href: "/" },
                    on: { click: _vm.doLogout },
                  },
                  [
                    _vm._v(
                      "\n                            Logout\n                        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 px-0 mt-2" }, [
                _c("div", { staticClass: "alert alert-info" }, [
                  _c("strong", [_vm._v("Note:")]),
                  _vm._v(
                    " We will send a\n                            confirmation code to the phone number supplied\n                            above.\n                        "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }